// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { hideNotVisibleRepresentations } from "../../utils";
import { selectSystemByIndexArray } from "../../stateTree";
import { setVisibility } from "../../VisibilityToggle";
import produce from "immer";
export function toggleMultipleSystemsVisibility(vss, molstar, checkedSystemsNum) {
    const tree = vss.getState().loaded_structures;
    const sortedPositions = checkedSystemsNum.sort((a, b) => a - b);
    const newtree = produce(tree, (draft) => {
        for (const positionInTree of sortedPositions) {
            const syst = selectSystemByIndexArray(positionInTree, draft);
            if (!syst)
                return;
            let cellRefs = syst.cellRef;
            const currentVis = syst.visibility !== undefined ? syst.visibility : true;
            if (cellRefs)
                setVisibility(molstar, cellRefs, !currentVis);
            syst.visibility = !currentVis;
            if (!currentVis) {
                hideNotVisibleRepresentations(syst, molstar);
            }
        }
    });
    vss.getState().set_loaded_structures(newtree);
}
