// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { TableClickAction } from "..";
function findToggleColnamesInConf(rowActions) {
    return rowActions === null || rowActions === void 0 ? void 0 : rowActions.filter((act) => act.action === TableClickAction.toggleVisibility ||
        act.action === TableClickAction.loadFile).reduce((allCols, act) => {
        // const addCols = act.column.filter((col) => col !== "protein");
        return [...allCols, ...act.column];
    }, []);
}
function findToggleColnamesInTable(colNames, toggleVizColnamesConf) {
    let toggleVizColnames = [];
    colNames.forEach((colName) => {
        const isToggleCol = typeof colName === "string" &&
            toggleVizColnamesConf.some((toggleCol) => toggleCol.toLowerCase() === colName.toLowerCase());
        if (isToggleCol)
            toggleVizColnames.push(colName);
    });
    return toggleVizColnames;
}
function cleanFilePath(originalString, substringToRemove) {
    if (substringToRemove) {
        const _substringToRemove = substringToRemove.endsWith("/")
            ? substringToRemove
            : substringToRemove + "/";
        if (originalString.startsWith(_substringToRemove)) {
            return originalString.slice(_substringToRemove.length);
        }
    }
    if (originalString.startsWith("dc://")) {
        const fileName = originalString.split("=").pop();
        if (fileName)
            return fileName;
    }
    return originalString;
}
export function getRowIsActive(row, toggleVizColnames, visibleSystemsFiles, basePath, DCtable) {
    const rowToggleData = toggleVizColnames
        .map((colName) => row.original[colName])
        .filter((e) => !!e);
    if (rowToggleData.length === 0)
        return false;
    const visibleSystemFilesFlat = visibleSystemsFiles.flat();
    const visibleSystemFilesFlat_relPath = visibleSystemFilesFlat.map((systFile) => basePath || DCtable ? cleanFilePath(systFile, basePath) : systFile);
    const rowIsActive = rowToggleData.every((rowSystem) => rowSystem.charAt(0) === "/"
        ? visibleSystemFilesFlat.includes(rowSystem)
        : visibleSystemFilesFlat_relPath.includes(rowSystem));
    return rowIsActive;
}
export function getToggleVizColnames(rowActions, colNames) {
    const toggleVizColnamesConf = rowActions
        ? findToggleColnamesInConf(rowActions)
        : [];
    if (toggleVizColnamesConf.length === 0)
        return [];
    const toggleVizColnames = findToggleColnamesInTable(colNames, toggleVizColnamesConf);
    return toggleVizColnames;
    // if (toggleVizColnames.length <= 1) return toggleVizColnames;
    // return [];
}
