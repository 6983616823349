var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { create } from "zustand";
import { PluginStateObject } from "molstar/lib/mol-plugin-state/objects";
import { StateObjectRef, StateSelection } from "molstar/lib/mol-state";
import { basicStructureQueries } from "../BasicStructureQueries";
import { dispatchNotificationEvent } from "../../NotificationSystem/utils";
import { defineColorParams } from "./utils";
export function generateRepName(molstar, cellRef) {
    var _a;
    const defaultName = `${cellRef}_${0}`;
    if (!molstar)
        return defaultName;
    // Find last representation in the system, if any
    const lastRep = molstar.state.data
        .selectQ((q) => q
        .byRef(cellRef)
        .subtree()
        .ofType(PluginStateObject.Molecule.Structure.Representation3D))
        .pop();
    if (!lastRep)
        return defaultName;
    // Find the name of the parent structure
    const lastRepStruc = StateSelection.findAncestorOfType(molstar.state.data.tree, molstar.state.data.cells, lastRep.transform.ref, PluginStateObject.Molecule.Structure);
    const lastName = (_a = lastRepStruc === null || lastRepStruc === void 0 ? void 0 : lastRepStruc.transform.tags) === null || _a === void 0 ? void 0 : _a.pop();
    const lastNum = Number(lastName === null || lastName === void 0 ? void 0 : lastName.split("_").pop());
    return `${cellRef}_${lastNum || lastNum === 0 ? lastNum + 1 : 0}`;
}
export const AtomSelectionStore = create((set, get) => ({
    selection: "",
    molstar_struct_ref: "",
    type: "ball-and-stick",
    alpha: 1,
    color: "element-symbol",
    color_value: 13421772,
    carbon_color_value: undefined,
    bfactor_color_range: undefined,
    bfactor_colors: undefined,
    visibility: true,
    aromaticBonds: false,
    ignoreLight: false,
    refs: [],
    set_alpha: (x) => set({ alpha: x }),
    set_visibility: (vis) => set({ visibility: vis === undefined ? true : vis }),
    set_type: (x) => set({ type: x }),
    set_color: (x) => set({ color: x }),
    set_color_value: (x) => set({ color_value: x }),
    set_carbon_color_value: (x) => set({ carbon_color_value: x }),
    set_bfactor_color_range: (x) => set({ bfactor_color_range: x }),
    set_bfactor_bfactor_colors: (x) => set({ bfactor_colors: x }),
    set_aromaticBonds: (x) => set({ aromaticBonds: x }),
    set_ignoreLight: (x) => set({ ignoreLight: !!x }),
    /* TODO: This function could be merged with "submit_query()" since it
    is the only function consuming "molstar_struct_ref" */
    set_molecule: (ref) => set({ molstar_struct_ref: ref }),
    set_selection: (q) => set({ selection: q }),
    submit_query: (molstar, pyodide, moleculeID = "", isSdf, idx) => new Promise((resolve, reject) => __awaiter(void 0, void 0, void 0, function* () {
        const selectionQuery = get().selection;
        const selection_arr = yield pyodide.RunPythonAsync({
            context: { moleculeID, selectionQuery, isSdf },
            script: `
        from pyodide.ffi import to_js
        from js import moleculeID, selectionQuery, isSdf
        
        def selectMatchingAtoms(moleculeID, selectionQuery, isSdf):
          try:
            if isSdf is True:
              mol = systems[moleculeID]['sdfs'][${idx}]
            else:
              mol = systems[moleculeID]['molecule']
            try:
              selection_arr = mol.atomselect(selectionQuery, True)
              return selection_arr
            except:
              print('Invalid selection query: ', selectionQuery)
          except Exception as e:
            return {'error': traceback.format_exc()}

        to_js(selectMatchingAtoms(moleculeID, selectionQuery, isSdf), depth=1)
      `,
        });
        if (selection_arr instanceof Map && selection_arr.get("error")) {
            reject(selection_arr.get("error"));
            dispatchNotificationEvent({
                message: selection_arr.get("error"),
                type: "error",
            });
            return;
        }
        if (selection_arr.length === 0) {
            reject(`Empty selection array!`);
            return;
        }
        const _q = basicStructureQueries.atoms(selection_arr);
        const cellRef = get().molstar_struct_ref;
        const structureCell = StateObjectRef.resolveAndCheck(molstar.state.data, cellRef);
        if (!structureCell) {
            reject("Invalid selection.");
            return;
        }
        const repName = generateRepName(molstar, cellRef);
        const component = yield molstar.builders.structure.tryCreateComponentFromExpression(structureCell, _q, repName /* user-defined representation name*/);
        if (!component) {
            reject("Invalid selection query.");
            return;
        }
        const visibility = get().visibility === undefined ? true : get().visibility;
        const representation = yield molstar.builders.structure.representation.addRepresentation(component, {
            type: get().type,
            typeParams: {
                alpha: get().alpha,
                aromaticBonds: get().aromaticBonds,
                multipleBonds: "symmetric",
                ignoreLight: get().ignoreLight,
            },
            color: get().color,
            colorParams: defineColorParams(get().color, get().color_value, get().carbon_color_value, get().bfactor_color_range, get().bfactor_colors),
        }, {
            initialState: {
                isHidden: !visibility,
            },
        });
        // if (!get().visibility) setVisibility(molstar, [representation.ref], true);
        const x = {
            cellRef: cellRef,
            repRef: representation.ref,
            query: selectionQuery,
        };
        resolve(x);
    })),
}));
