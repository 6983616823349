const h = "calc(100vh + 2px)";
const rightPanel = {
    backgroundColor: "white",
    position: "fixed",
    display: "block",
    width: "40vw",
    top: "-1px",
    height: h,
    right: 0,
};
const systemTree = {
    overflowX: "auto",
    position: "fixed",
    display: "block",
    width: `23em`,
    top: "-1px",
    zIndex: 1,
};
const leftPanel = {
    // borderRight: "1px solid #DCDCDC",
    overflowX: "auto",
    position: "fixed",
    display: "block",
    width: `25em`,
    top: "-1px",
    height: h,
    zIndex: 2,
};
const leftPanelRepresentations = {
    overflowX: "auto",
    position: "fixed",
    display: "block",
    width: `25em`,
};
// const topPanel: CSSProperties = {
//   backgroundColor: "white",
//   position: "fixed",
//   display: "block",
//   width: "100%",
//   maxHeight: "140px",
//   top: 0,
//   overflowY: "auto",
//   //-------
//   // height: "15vh",
// };
const viewer = {
    top: "-1px",
    display: "block",
    position: "fixed",
    height: h,
};
const bottomPanel = {
    backgroundColor: "white",
    position: "fixed",
    display: "block",
    width: "100%",
    height: "20vh",
    // right: 0,
    bottom: 0,
};
//@ts-ignore
const isElectron = !!window.electron;
const consoleMinHeight = isElectron ? "0em" : "1.75em";
export { rightPanel, leftPanel, viewer, bottomPanel, 
// topPanel,
leftPanelRepresentations, systemTree, consoleMinHeight, };
