import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Link, Typography } from "@mui/material";
import { NAPMSAStore } from "../StateStores";
import { shallow } from "zustand/shallow";
import { memo } from "react";
export function MSAPositions({ msaRef, systNameSpace, }) {
    const interval = 50;
    return (_jsx(Box, Object.assign({ display: "flex", flexDirection: "row", sx: {
            backgroundColor: "white",
            position: "sticky",
            top: 0,
            pl: `calc(${systNameSpace} + 0.5rem)`,
            height: "15px",
            zIndex: 4,
        } }, { children: msaRef.map((e, i) => {
            const intervalPosShown = i % interval === 0;
            return (_jsx(MSAPosition, { idx: i, intervalPosShown: intervalPosShown }, `seq-position-${i}`));
        }) })));
}
const MSAPosition = memo(MSAPositionMemo);
function MSAPositionMemo({ idx, intervalPosShown, }) {
    const isHovered = NAPMSAStore((state) => state.hoveredMsaPos === idx, shallow);
    return (_jsxs(Box, Object.assign({ sx: {
            position: "relative",
            textAlign: "left",
            width: "8px",
        } }, { children: [intervalPosShown && (_jsx(Typography, Object.assign({ variant: "caption", color: "primary", sx: {
                    position: "absolute",
                    // top: "-12px",
                    fontSize: "10px",
                } }, { children: idx }))), _jsx(Box, Object.assign({ visibility: isHovered ? "visible" : "hidden", sx: {
                    position: "absolute",
                    zIndex: 10,
                    backgroundColor: "secondary.main",
                    borderRadius: "2px",
                } }, { children: _jsx(Typography
                // variant="caption"
                , Object.assign({ 
                    // variant="caption"
                    color: "primary", sx: {
                        color: "white",
                        fontSize: "10px",
                        fontFamily: "monospace",
                        p: "1px",
                    } }, { children: idx })) })), _jsx(Link, Object.assign({ className: "sequencePosition", component: "button", underline: "none", sx: {
                    fontFamily: "monospace",
                    fontSize: "0.8rem",
                    color: "black",
                    visibility: "hidden",
                } }, { children: "." }))] })));
}
