var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import produce from "immer";
import { FileType } from "../";
import { renderSystem } from "./renderSystem";
/**
 * Renders systems recursively based on the provided loading instructions
 * and adds successfully rendered systems to viewer state.
 */
export function loadSystems(acceptedFilesObject, loadingInstructions, vss, molstar, pyodide, quiet, archivedOnLoad) {
    var _a, e_1, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        const { systems } = loadingInstructions;
        if (systems) {
            try {
                for (var _d = true, systems_1 = __asyncValues(systems), systems_1_1; systems_1_1 = yield systems_1.next(), _a = systems_1_1.done, !_a;) {
                    _c = systems_1_1.value;
                    _d = false;
                    try {
                        let system = _c;
                        yield traverseSystemTree(vss, molstar, pyodide, acceptedFilesObject, systems, system, quiet, archivedOnLoad);
                    }
                    finally {
                        _d = true;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (!_d && !_a && (_b = systems_1.return)) yield _b.call(systems_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            const prevSystems = vss.getState().loaded_structures;
            const newSystems = produce(prevSystems, (draft) => {
                // Remove all systems without cellRef (it means they failed)
                const systemsWithoutFailedEntries = systems.filter((s) => s.cellRef || s.type === FileType.group);
                draft.push(...systemsWithoutFailedEntries);
            });
            vss.getState().set_loaded_structures(newSystems);
            return newSystems;
        }
    });
}
/**
 * Recursively renders the files of each MolSystem in the passed system tree.
 */
function traverseSystemTree(vss, molstar, pyodide, acceptedFiles, allSystems, currentSystem, quiet, archivedOnLoad) {
    var _a, e_2, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        if (archivedOnLoad) {
            currentSystem.visibility = false;
            currentSystem.archived = true;
        }
        if (currentSystem.files) {
            yield renderSystem(vss, molstar, pyodide, acceptedFiles, currentSystem, quiet);
        }
        if (currentSystem.children && currentSystem.type === FileType.group) {
            try {
                for (var _d = true, _e = __asyncValues(currentSystem.children), _f; _f = yield _e.next(), _a = _f.done, !_a;) {
                    _c = _f.value;
                    _d = false;
                    try {
                        let s = _c;
                        yield traverseSystemTree(vss, molstar, pyodide, acceptedFiles, allSystems, s, quiet, archivedOnLoad);
                    }
                    finally {
                        _d = true;
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (!_d && !_a && (_b = _e.return)) yield _b.call(_e);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
    });
}
