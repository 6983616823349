var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import produce from "immer";
import { Box, ListItemButton, ListItemIcon, Tooltip } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { FileType, NAPPlotStore, } from "..";
import { downloadObjectAsJson } from "../utils/saveAsJson";
import { NAPMSAStore } from "../StateStores/MSA.store";
import { NAPTableStore } from "../StateStores/tables.store";
export const SaveButton = ({ vss }) => {
    return (_jsx(Tooltip, Object.assign({ enterDelay: 200, title: "Save Viewer State", arrow: true, placement: "right" }, { children: _jsx(ListItemButton, Object.assign({ onClick: () => constructSavefile(vss.getState().loaded_structures), disabled: vss.getState().loaded_structures.length === 0 &&
                NAPTableStore.getState().tables.length === 0 }, { children: _jsx(ListItemIcon, { children: _jsx(Box, Object.assign({ sx: { color: "secondary.light" } }, { children: _jsx(SaveIcon, {}) })) }) }), "save-current-nap-state") })));
};
function cleanTreeForDownload(system) {
    if (system.children) {
        system.children.forEach((childSyst) => cleanTreeForDownload(childSyst));
    }
    else {
        delete system["cellRef"];
        delete system["moleculeID"];
        delete system["stateTreePosition"];
        if (system.type === FileType.volume) {
            const volParams = system.volumeRepresentation;
            delete volParams.minIsoValue;
            delete volParams.maxIsoValue;
        }
        else {
            if (system.representations) {
                system.representations.forEach((rep) => {
                    delete rep.refs;
                });
            }
        }
    }
}
function getDateNow() {
    const date = new Date(Date.now());
    const y = date.getFullYear();
    const m = date.getMonth() + 1;
    const d = date.getDate();
    const hrs = date.getHours();
    const min = date.getMinutes();
    const dateformat = `${y}-${m}-${d}_${hrs}-${min}`;
    return dateformat;
}
function constructSavefile(loaded_structures) {
    return __awaiter(this, void 0, void 0, function* () {
        const internalTree = loaded_structures;
        const downloadTree = produce(internalTree, (draft) => {
            draft.forEach((system) => {
                cleanTreeForDownload(system);
            });
        });
        const savefile = {
            systems: downloadTree,
        };
        const tablesData = NAPTableStore.getState().tables;
        if (tablesData.length > 0) {
            const tables = tablesData.map((e) => {
                const d = {
                    path: e.DCpath ? `${e.DCpath}?files=${e.name}` : e.name,
                    name: e.name,
                };
                if (e.options)
                    d["options"] = e.options;
                return d;
            });
            savefile["tables"] = tables;
        }
        const plotsData = NAPPlotStore.getState().plots;
        if (plotsData.length > 0) {
            const plots = plotsData.map((e) => {
                const d = {
                    path: e.DCpath ? `${e.DCpath}?files=${e.name}` : e.name,
                };
                if (e.options)
                    d["options"] = e.options;
                return d;
            });
            savefile["plots"] = plots;
        }
        const msaArr = NAPMSAStore.getState().msaArr;
        if (msaArr.length > 0) {
            const msas = msaArr.map((e) => {
                const d = {
                    path: e.DCpath ? `${e.DCpath}?files=${e.name}` : e.name,
                    name: e.name,
                };
                return d;
            });
            savefile["MSAs"] = msas;
        }
        const outFileName = `config_${getDateNow()}`;
        downloadObjectAsJson(savefile, outFileName);
    });
}
