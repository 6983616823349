import { arrayIsEqual } from "../utils/arrayIsEqual";
import { create } from "zustand";
export var Panels;
(function (Panels) {
    Panels["viewer"] = "viewer";
    Panels["statetree"] = "statetree";
    Panels["reps"] = "reps";
    Panels["plots"] = "plots";
    Panels["apps"] = "apps";
    Panels["msa"] = "msa";
    Panels["console"] = "console";
    Panels["sequence"] = "sequence";
})(Panels || (Panels = {}));
const defaultPanelSetup = {
    [Panels.viewer]: true,
    [Panels.statetree]: false,
    [Panels.sequence]: false,
    [Panels.reps]: false,
    [Panels.plots]: false,
    [Panels.msa]: false,
    [Panels.apps]: false,
    [Panels.console]: false,
};
/** The FileMap-type objects are parsed by chonky's hooks in order to
 * render the file list and the folder path. The default value passed
 * to the state is essentially an empty directory.
 */
const emptyFileMap = {
    rootFolderId: "Projects",
    fileMap: {
        Projects: {
            id: "Projects",
            name: "Projects",
            isDir: true,
            childrenIds: [],
            childrenCount: 0,
        },
    },
};
export const NAPGenericStore = create((set, get) => ({
    _fileSelector: () => { },
    _systemRadioSelector: () => { },
    _activeSystemRadio: "",
    _filterRadio: undefined,
    _openLeftPanel: false,
    _openRightPanel: false,
    _openBottomPanel: false,
    _requireFilesWExtension: [],
    _selectedSidebarEntry: "",
    _openFileAddPanel: false,
    _ligandPrepareSelectedFile: { selection_name: "", selection_id: "" },
    _openFileContentPanel: false,
    _openFileBrowserDialog: false,
    _openFileUploadDialog: false,
    _openFileDetailsDialog: false,
    _userFiles: emptyFileMap,
    _contentPanelFileId: "",
    _contentPanelLocalFilePath: "",
    _fileDetails: {
        id: "Projects",
        name: "Projects",
        isDir: true,
        childrenIds: [],
        childrenCount: 0,
    },
    _fileBrowserSelFile: { selection_name: "", selection_id: "" },
    _activePanels: defaultPanelSetup,
    _activeSystemPosition: undefined,
    highligtedTableSystemRef: [],
    visibleSystemsFiles: [],
    loadingDCFile: false,
    sideBarWidth: "57px",
    dataVizDetails: {},
    topPanelHeight: 0,
    isFullscreen: false,
    representationsPanelPosition: {
        x: 0,
        y: 0,
    },
    representationsPanelWasDragged: false,
    setOpenFileContentPanel: (openFileContentPanel) => set({ _openFileContentPanel: openFileContentPanel }),
    setFileSelector: (fileSelector) => set({ _fileSelector: fileSelector }),
    setSystemRadioSelector: (systemRadioSelector) => set({ _systemRadioSelector: systemRadioSelector }),
    setActiveSystemRadio: (activeSystemRadio) => set({ _activeSystemRadio: activeSystemRadio }),
    setFilterRadio: (filterRadio) => set({ _filterRadio: filterRadio }),
    setOpenLeftPanel: (openLeftPanel) => set({ _openLeftPanel: openLeftPanel }),
    setOpenRightPanel: (openRightPanel) => set({ _openRightPanel: openRightPanel }),
    setOpenBottomPanel: (openBottomPanel) => set({ _openBottomPanel: openBottomPanel }),
    setRequireFilesWExtension: (requireFilesWExtension) => set({ _requireFilesWExtension: requireFilesWExtension }),
    setSelectedSidebarEntry: (selectedSidebarEntry) => set({ _selectedSidebarEntry: selectedSidebarEntry }),
    setOpenFileAddPanel: (openFileAddPanel) => set({ _openFileAddPanel: openFileAddPanel }),
    setLigandPrepareSelectedFile: (ligandPrepareSelectedFile) => set({ _ligandPrepareSelectedFile: ligandPrepareSelectedFile }),
    setOpenFileBrowserDialog: (openFileBrowserDialog) => set({ _openFileBrowserDialog: openFileBrowserDialog }),
    setOpenFileUploadDialog: (openFileUploadDialog) => set({ _openFileUploadDialog: openFileUploadDialog }),
    setOpenFileDetailsDialog: (openFileDetailsDialog) => set({ _openFileDetailsDialog: openFileDetailsDialog }),
    setUserFiles: (userFiles) => set({ _userFiles: userFiles }),
    setContentPanelFileId: (id) => set({ _contentPanelFileId: id }),
    setContentPanelLocalFilePath: (localFilePath) => set({ _contentPanelLocalFilePath: localFilePath }),
    setFileDetails: (details) => set({ _fileDetails: details }),
    setFileBrowserSelFile: (selection) => set({ _fileBrowserSelFile: selection }),
    setActivePanels: (activePanels) => set({ _activePanels: activePanels }),
    resetActivePanels: () => set({ _activePanels: defaultPanelSetup }),
    setActiveSystemPosition: (position) => set({ _activeSystemPosition: position }),
    setHighligtedTableSystemRef: (cellRefs) => set({ highligtedTableSystemRef: cellRefs }),
    setLoadingDCFile: (isLoading) => set({ loadingDCFile: isLoading }),
    setSideBarWidth: (val) => set({ sideBarWidth: val }),
    addVisibleSystemsFiles: (systFiles) => {
        set((state) => ({
            visibleSystemsFiles: [...state.visibleSystemsFiles, systFiles],
        }));
    },
    removeVisibleSystemsFiles: (systFiles) => {
        set((state) => ({
            visibleSystemsFiles: state.visibleSystemsFiles.filter((e) => !arrayIsEqual(e, systFiles)),
        }));
    },
    setDataVizDetails: (data) => {
        set({
            dataVizDetails: data,
        });
    },
    setTopPanelHeight: (height) => {
        set({
            topPanelHeight: height,
        });
    },
    toggleFullscreen: () => set((state) => ({ isFullscreen: !state.isFullscreen })),
    setRepresentationsPanelPosition: (xyPosition) => set({ representationsPanelPosition: xyPosition }),
    closeSystemRepresentations: () => {
        set({ _openBottomPanel: false });
        setTimeout(() => {
            set({ _activeSystemPosition: undefined });
        }, 200);
    },
    setRepresentationsPanelWasDragged: (x) => set({ representationsPanelWasDragged: x }),
}));
export var SidebarEntryKeys;
(function (SidebarEntryKeys) {
    SidebarEntryKeys["apps"] = "pm-sidebar-apps";
    SidebarEntryKeys["files"] = "pm-sidebar-files";
    SidebarEntryKeys["jobs"] = "pm-sidebar-jobs";
    SidebarEntryKeys["ligands"] = "pm-sidebar-ligands";
    SidebarEntryKeys["representations"] = "pm-sidebar-representations";
})(SidebarEntryKeys || (SidebarEntryKeys = {}));
export var PanelPosition;
(function (PanelPosition) {
    PanelPosition["left"] = "pm-left-panel";
    PanelPosition["right"] = "pm-right-panel";
    PanelPosition["bottom"] = "pm-bottom-panel";
})(PanelPosition || (PanelPosition = {}));
export var Apps;
(function (Apps) {
    Apps["jobs"] = "jobs";
    Apps["datacenter"] = "datacenter";
    Apps["proteinPrepare"] = "proteinPrepare";
    Apps["deepSite"] = "deepSite";
    Apps["kDeep"] = "kDeep";
    Apps["kDeepTrainer"] = "kDeepTrainer";
    Apps["systemBuilder"] = "systemBuilder";
    Apps["fepGraph"] = "fepGraph";
    Apps["Parameterize"] = "Parameterize";
})(Apps || (Apps = {}));
