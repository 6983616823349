import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Tooltip, Typography } from "@mui/material";
export function MSASeqName({ space, systemName, }) {
    return (_jsx(_Fragment, { children: _jsx(Box, Object.assign({ sx: {
                maxWidth: space,
                overflow: "hidden",
                textOverflow: "ellipsis",
                backgroundColor: "white",
            } }, { children: _jsx(Tooltip, Object.assign({ arrow: true, enterDelay: 1000, title: systemName }, { children: _jsx(Typography, Object.assign({ variant: "caption", sx: {
                        px: 1,
                        py: "4px",
                        fontSize: "10px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        color: "#0b5394",
                    } }, { children: systemName })) })) })) }));
}
