import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { MSASeqName } from "./MSASeqName";
export function MSAVirtualizedNames({ virtualRows, msaEntries, systNameSpace, }) {
    return (_jsx(Box, Object.assign({ id: "msa-names", sx: {
            position: "sticky",
            left: 0,
            zIndex: 1,
            // height: "3px",
        } }, { children: virtualRows.map((virtualRow) => {
            const name = msaEntries[virtualRow.index][0];
            return (_jsx("div", Object.assign({ className: virtualRow.index % 2 ? "ListItemOdd" : "ListItemEven", style: {
                    maxWidth: systNameSpace,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: `${virtualRow.size}px`,
                    transform: `translateY(${virtualRow.start}px)`,
                    backgroundColor: "white",
                } }, { children: _jsx(MSASeqName, { space: systNameSpace, systemName: name }) }), `name-${virtualRow.index}`));
        }) })));
}
