var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import produce from "immer";
import { dispatchNotificationEvent } from "../NotificationSystem/utils";
import { toAbsPath } from "./utils";
import { renderDataCenterSystem, renderSystem } from "./renderSystem";
export function createNewSystemFromLocalTrajFrames(vss, molstar, pyodide, framesToLoad, localBasePath) {
    return __awaiter(this, void 0, void 0, function* () {
        framesToLoad.files.forEach((frameInfo) => {
            frameInfo.model = toAbsPath(localBasePath, frameInfo.model);
            frameInfo.trajectory = frameInfo.trajectory.map((tpath) => toAbsPath(localBasePath, tpath));
        });
        //@ts-ignore
        const wIPCR = window.ipcr;
        if (!wIPCR)
            return;
        const result = yield wIPCR.invoke("load-traj-frames", framesToLoad.files);
        if (!result["data"] && result["error"]) {
            dispatchNotificationEvent({ message: result["error"], type: "error" });
            return;
        }
        const files = result["data"];
        const fileObj = Object.assign({}, ...files.map((x) => ({
            [x.name]: x,
        })));
        const system = framesToLoad.system
            ? framesToLoad.system
            : {
                name: files.map((e) => e.name).join(", "),
            };
        system["files"] = files.map((e) => e.name);
        yield renderSystem(vss, molstar, pyodide, fileObj, system);
        if (!system["cellRef"])
            return;
        const prevSystems = vss.getState().loaded_structures;
        const newSystems = produce(prevSystems, (draft) => {
            draft.push(system);
        });
        vss.getState().set_loaded_structures(newSystems);
        return system;
    });
}
export function createNewSystemFromLocal(vss, molstar, pyodide, topFilePath, fileName, category, representations) {
    return __awaiter(this, void 0, void 0, function* () {
        //@ts-ignore
        const wIPCR = window.ipcr;
        if (!wIPCR)
            return;
        const systFile = yield wIPCR.invoke("read-local-file", topFilePath);
        systFile;
        if (!systFile)
            return;
        const system = {
            name: fileName ? fileName : topFilePath,
            files: [topFilePath],
            category: category,
            representations: representations,
        };
        yield renderSystem(vss, molstar, pyodide, { [topFilePath]: systFile }, system);
        if (!system["cellRef"])
            return;
        const prevSystems = vss.getState().loaded_structures;
        const newSystems = produce(prevSystems, (draft) => {
            draft.push(system);
        });
        vss.getState().set_loaded_structures(newSystems);
        return system;
    });
}
export function createNewSystemFromDC(vss, molstar, pyodide, topFilePath, trajFilePath, loadMultiple, category, representations) {
    return __awaiter(this, void 0, void 0, function* () {
        const newSystem = yield renderDataCenterSystem(vss, molstar, pyodide, topFilePath, trajFilePath, undefined, loadMultiple, undefined, representations);
        if (!newSystem)
            return;
        newSystem.category = category;
        const prevSystems = vss.getState().loaded_structures;
        const newSystems = produce(prevSystems, (draft) => {
            draft.push(newSystem);
        });
        vss.getState().set_loaded_structures(newSystems);
        return newSystem;
    });
}
