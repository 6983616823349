import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Divider, Grid, Typography } from "@mui/material";
import { clearHighlight, highlightStructure, } from "../../utils/HighlightStructure";
export function RefSystemIndicator({ refSystName, cellRef, molstar, }) {
    const onMouseEnter = () => {
        highlightStructure(molstar, [cellRef]);
    };
    const onMouseLeave = () => {
        clearHighlight(molstar);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Divider, { orientation: "vertical", variant: "middle", flexItem: true }), _jsxs(Grid, Object.assign({ sx: { px: 1 }, item: true, xs: "auto", onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave }, { children: [_jsx(Typography, Object.assign({ display: "inline", variant: "caption", sx: {
                            fontSize: "10px",
                            pr: "5px",
                        } }, { children: "Ref. system:" })), _jsx(Typography, Object.assign({ display: "inline", variant: "caption", sx: {
                            fontSize: "10px",
                            fontWeight: "bold",
                        } }, { children: refSystName }))] }))] }));
}
